import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom/client';
import './App.css'
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import PreLoader from './Components/PreLoader';

const App = lazy(() => import('./App'));
const Login = lazy(() => import('./Pages/Login'));
const Registration = lazy(() => import('./Pages/Registration'));
const Class = lazy(() => import('./Pages/Class'));
const Exam = lazy(() => import('./Pages/Exam'));
const Subject = lazy(() => import('./Pages/Subject'));
const Subjectvedio = lazy(() => import('./Pages/Subjectvedio'));
const Subjectinner = lazy(() => import('./Pages/Subjectinner'));
const Forget = lazy(() => import('./Pages/Forget'));
const Examdetail = lazy(() => import('./Pages/Examdetail'));
const Subjectdetail = lazy(() => import('./Pages/Subjectdetail'));
const Classdetail = lazy(() => import('./Pages/Classdetail'));
const Singlevedio = lazy(() => import('./Pages/Singlevedio'));
const Liveactive = lazy(() => import('./Pages/Liveactive'));
const Liveprevious = lazy(() => import('./Pages/Liveprevious'));
const Liveupcoming = lazy(() => import('./Pages/Liveupcoming'));
const Livevedio = lazy(() => import('./Pages/Livevedio'));
const Chaptervedio = lazy(() => import('./Pages/Chaptervedio'));
const Payment = lazy(() => import('./Pages/Payment'));
const PayNow = lazy(() => import('./Pages/PayNow'));
const Watchhistory = lazy(() => import('./Pages/Watchhistory'));
const Watchhistoryvideo = lazy(() => import('./Pages/Watchhistoryvideo'));
const Singlehistoryvideo = lazy(() => import('./Pages/Singlehistoryvideo'));
const User = lazy(() => import('./Pages/User'));
const Otp = lazy(() => import('./Pages/Otp'));
const Thankyou = lazy(() => import('./Pages/Thankyou'));
const Cancelpayment = lazy(() => import('./Pages/Cancelpayment'));
const Securityerror = lazy(() => import('./Pages/Securityerror'));

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

  <BrowserRouter >
    <Suspense fallback={<PreLoader />}>
      <Routes>
        <Route path="/app/" element={<App />}>
          <Route path="/app/" element={<Login />} />
          <Route path="/app/forget-password" element={<Forget />} />
          <Route path="/app/register" element={<Registration />} />
          <Route path="/app/class" element={<Class />} />
          <Route path="/app/exam" element={<Exam />} />
          <Route path="/app/classes/:id" element={<Examdetail />} />
          <Route path="/app/subject" element={<Subject />} />
          <Route path="/app/chapters/:id" element={<Subjectvedio />} />
          <Route path="/app/units/:id" element={<Subjectinner />} />
          <Route path="/app/subject-units/:id" element={<Subjectdetail />} />
          <Route path="/app/class-subjects/:id" element={<Classdetail />} />
          <Route path="/app/single-video" element={<Singlevedio />} />
          <Route path="/app/live-active/:id" element={<Liveactive />} />
          <Route path="/app/live-previous/:id" element={<Liveprevious />} />
          <Route path="/app/live-upcoming/:id" element={<Liveupcoming />} />
          <Route path="/app/live-video" element={<Livevedio />} />
          <Route path="/app/chapter-video/:id" element={<Chaptervedio />} />
          <Route path="/app/payment" element={<Payment />} />
          <Route path="/app/pay-now" element={<PayNow />} />
          <Route path="/app/watch-history" element={<Watchhistory />} />
          <Route path="/app/watch-history-video" element={<Watchhistoryvideo />} />
          <Route path="/app/single-history-video" element={<Singlehistoryvideo />} />
          <Route path="/app/user-profile" element={<User />} />
          <Route path="/app/otp" element={<Otp />} />
          <Route path="/app/thank-you" element={<Thankyou />} />
          <Route path="/app/payment-cancel" element={<Cancelpayment />} />
          <Route path="/app/security-error" element={<Securityerror />} />
        </Route>
      </Routes>
    </Suspense>
  </BrowserRouter>

);

reportWebVitals();
